import { CreatableRow, MutableRow } from '@ft/lib/core-lib';

import { ApiCreateProp, ApiMutateProp } from '@ft/lib/core-lib';
import { CreateRowRequest, GetPagedDataRequest, MutateRowRequest } from '@ft/lib/crud-helper-lib';
import { StoreDefinition } from '@ft/lib/indexeddb-lib';
import { createSignalStore } from '@ft/lib/signal-store-lib';
import { OrganisationCardComponent } from '../components/organisation-card/organisation-card.component';
import { ApiUrls } from '../data/api.constants';
import {
	getManyProperty,
	nameProperty,
	resultProperty,
	signalStoreName,
	sortProperty,
} from '../data/organisation.constants';
import { Organisation, OrganisationSearchBarFields } from '../organisation.model';

const getPagedDataRequest: GetPagedDataRequest<Organisation> = {
	getManyProperty: getManyProperty,
	getManyApi: ApiUrls.organisations.getOrganisations(),
	chunkSize: 50,
};

const createRowRequest: CreateRowRequest<Organisation> = {
	apiCreate: (record: CreatableRow<Organisation>) => ApiUrls.organisations.organisationCreateOne(record),
	apiCreateProp: ApiCreateProp,
	row: new Organisation(),
	notify: true,
	notifyKey: nameProperty,
};

const mutateRowRequest: MutateRowRequest<Organisation> = {
	apiMutate: (row: MutableRow<Organisation>) =>
		ApiUrls.organisations.organisationUpdateById(row._id, row as Organisation),
	apiMutateProp: ApiMutateProp,
	row: new Organisation(),
	notify: true,
	notifyKey: nameProperty,
};

const sortFunction = (a: Organisation, b: Organisation): number => {
	return (a.organisationName ?? '').toLocaleLowerCase().localeCompare((b.organisationName ?? '').toLocaleLowerCase());
};

const selectById = (organisation: Organisation) => organisation._id;

/**
 * Indexed DB defintions
 */
export const ORGANISATIONS_STORE: StoreDefinition = {
	tableName: `Organisations`,
	keyPath: `_id`,
	autoIncrement: false,
	indexFields: `_id, updatedAt, name, retrievedAt`,
};

/**
 * Create signal store
 */
export const OrganisationStore = createSignalStore<Organisation>({
	signalStoreName: signalStoreName,
	getPagedDataRequest: getPagedDataRequest,
	createRowRequest: createRowRequest,
	mutateRowRequest: mutateRowRequest,
	apiDelete: ApiUrls.organisations.organisationDeleteById,
	editComponent: OrganisationCardComponent,
	editResultProperty: resultProperty,
	nameProperty: nameProperty,
	sortProperty: sortProperty,
	searchBarFields: OrganisationSearchBarFields,
	loadOn: 'auth',
	classConstructor: Organisation,

	indexedDBConfig: {
		indexedDBName: 'organisations',
		indexedDBVersion: 1.6,
		indexedDBStoreList: [ORGANISATIONS_STORE],
	},
});

export type OrganisationStoreType = typeof OrganisationStore;
