import { FormlyFieldConfig } from '@ngx-formly/core';

export const OrganisationCardFields: FormlyFieldConfig[] = [
	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			// nothing to see here
		},
		fieldGroup: [
			{
				className: 'flex-0',
				type: 'image-upload',
				key: 'organisationLogo',
				props: {
					label: 'Logo',
					placeholder: 'Org\r\nLogo',
					readonly: false,
					height: '56px',
					width: '64px',
				},
				expressions: {
					// 'props.disabled': '!model.organisationName',
				},
			},
			{
				className: 'flex-1',
				type: 'input',
				key: 'organisationName',
				props: {
					label: 'Organisation Name',
					placeholder: 'Enter the organisation name',
					readonly: false,
					required: true,
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			'props.disabled': '!model.organisationName',
			hide: '!model.organisationName',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'input',
				key: 'desc',
				props: {
					label: 'Description',
					placeholder: 'Enter a description of the organisation',
					readonly: false,
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			'props.disabled': '!model.organisationName',
			hide: '!model.organisationName',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'input',
				key: 'address',
				props: {
					label: 'Address',
					placeholder: 'Enter the main address',
					readonly: false,
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			'props.disabled': '!model.organisationName',
			hide: '!model.organisationName',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'input',
				key: 'email',
				props: {
					label: 'Email',
					placeholder: 'Enter the primary email',
					readonly: false,
				},
			},
			{
				className: 'flex-1',
				type: 'input',
				key: 'website',
				props: {
					label: 'Website',
					placeholder: 'Enter the organisation website',
					readonly: false,
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			'props.disabled': '!model.organisationName',
			hide: '!model.organisationName',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'radio',
				key: 'deleted',
				props: {
					label: 'Status',
					placeholder: 'Active organisation?',
					readonly: false,
					options: [
						{ value: false, label: 'Active' },
						{ value: true, label: 'Not Active' },
					],
				},
			},
			{
				className: 'flex-1',
				type: 'radio',
				key: 'tickType',
				defaultValue: 'none',
				props: {
					label: 'Tick Type',
					placeholder: 'Enter tick colour',
					readonly: false,
					options: [
						{ value: 'blue', label: 'Blue' },
						{ value: null, label: 'None' },
					],
				},
			},
		],
	},
];
