import { CreatableRow, MutableRow } from '@ft/lib/core-lib';
import { ModeType } from '@furnas-technology/angular-library';
import { FilteredRecord, SearchBarField } from '@furnas-technology/common-library/filters';
import { Organisation } from '@furnas-technology/common-library/organisations';

// -------------------------------
// organisation
// -------------------------------
export type OrganisationMutateRecord = MutableRow<Organisation>;
export type OrganisationCreateRecord = CreatableRow<Organisation>;

export { Organisation };

export interface OrganisationDialogModel {
	modeType: ModeType;
	id: string;
}

export const OrganisationSearchBarFields: SearchBarField<Organisation>[] = [
	new SearchBarField<Organisation>('organisationName', ['string']),
	new SearchBarField<Organisation>('desc', ['string']),
	new SearchBarField<Organisation>('address', ['string']),
];

export type FilteredOrganisation = FilteredRecord<Organisation>;
