import { gql } from 'apollo-angular';

import {
	Organisation,
	OrganisationCreateRecord,
	OrganisationMutateRecord,
} from '@danceShared/organisations-shared/organisation.model';
import { FT_propsRecord } from '@ft/lib/crud-helper-lib';

export const organisationQueryFields = [
	'organisationName',
	'desc',
	'address',
	'country',
	'email',
	'website',
	'organisationLogo',
	'tickType',
	'_competition_ids',

	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
];

export const organisationMutateFields: (keyof Organisation)[] = [
	'organisationName',
	'desc',
	'address',
	'country',
	'email',
	'website',
	'tickType',
	'organisationLogo',
	'deleted',
];

export const ApiUrls = {
	organisations: {
		totals: () => gql`
      query {
        totals: organisationTotals
      }
    `,

		getOrganisations: (fieldList = organisationQueryFields) => gql`
      query($filter: FilterFindManyOrganisationInput, $sort: SortFindManyOrganisationInput, $skip: Int, $limit: Int) {
        organisationCount
        organisationMany(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
          _id
          ${fieldList.join(`\r\n`)}
        }
      }
    `,

		organisationUpdateById: (_id: string, record: OrganisationMutateRecord) => {
			const mutateRecord = FT_propsRecord(record);

			return {
				mutation: gql`
          mutation ($_id: MongoID!, $record: UpdateByIdOrganisationInput!) {
            mutateResult: organisationUpdateById(_id: $_id, record: $record) {
              recordId
              record {
                _id
                ${organisationQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
				variables: { _id: _id, record: mutateRecord },
			};
		},
		organisationCreateOne: (record: OrganisationCreateRecord) => {
			const mutateRecord = FT_propsRecord(record);

			return {
				mutation: gql`
          mutation ($record: CreateOneOrganisationInput!) {
            createResult: organisationCreateOne(record: $record) {
              recordId
              record {
                _id
                ${organisationQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
				variables: { record: mutateRecord },
			};
		},

		organisationDeleteById: (_id: string) => {
			return {
				mutation: gql`
          mutation ($input: DocumentIdInputInput!) {
            deleteResult: organisationDeleteById(input: $input)
          }
        `,
				variables: { input: { documentId: `${_id}` } },
			};
		},
	},
};
