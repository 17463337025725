<div class="ft-dialog-component">
  <!-- loading bar -->
  <ft-progress-bar [isLoading]="rowsLoading()"></ft-progress-bar>

  <!-- card header  -->
  <div mat-dialog-title>
    <div class="title">
      <fa-icon [icon]="faUser"></fa-icon>
      <h4 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ headerTitle() }}</h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="hide-scrollbars">
        <formly-form
          [model]="model()"
          [fields]="fields"
          [options]="options"
          [form]="form"
          (modelChange)="onModelChange($event)"
        ></formly-form>
        <!-- <pre>{{ model | json }}</pre> -->
      </div>
    </form>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions>
    <button
      type="submit"
      mat-button
      class="submit-button"
      (click)="onSubmit()"
      [disabled]="!form.valid || !form.dirty"
      aria-label="Submit"
    >
      {{ form.valid || form.pristine || isInfoModeType() ? submitTitle() : 'Errors Exist' }}
    </button>
  </mat-dialog-actions>
</div>
