import { Injectable, computed, inject } from '@angular/core';

import { GroupByProperty } from '@ft/lib/facade-lib';
import { GenericStoreService, GenericStoreServiceInput } from '@ft/lib/store-service-lib';
import { Organisation } from '@furnas-technology/common-library/organisations';
import { OrganisationCardComponent } from '../components/organisation-card/organisation-card.component';
import { OrganisationStore } from '../store/organisation.store';
import { nameProperty, resultProperty, signalStoreName } from './organisation.constants';

const genericStoreServiceInput: GenericStoreServiceInput<Organisation> = {
	signalStoreName: signalStoreName,
	signalStore: OrganisationStore,

	viewComponent: OrganisationCardComponent,
	editComponent: OrganisationCardComponent,
	resultProperty: resultProperty,
	nameProperty: nameProperty,
};

@Injectable({
	providedIn: 'root',
})
export class OrganisationService extends GenericStoreService<Organisation> {
	readonly store = inject(OrganisationStore);

	/**
	 * store selector
	 */
	public organisationStore = computed(() => {
		return this.store;
	});

	constructor() {
		super(genericStoreServiceInput);
	}

	/**
	 *  virtual fields
	 */
	searchOrganisations = computed(() =>
		this.organisationStore()
			.filteredDocuments()
			.map((x) => x.record),
	);

	organisationName = computed(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchOrganisations(), ['organisationName'], 'notOmitted');
		const values = GroupByProperty(rows, 'organisationName');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});
} // end class
