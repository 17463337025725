import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, computed, effect, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

import { CdkDrag } from '@angular/cdk/drag-drop';
import {} from '@angular/cdk/keycodes';
import {} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { OrganisationService } from '@danceShared/organisations-shared/data/organisation.service';
import { Organisation } from '@danceShared/organisations-shared/organisation.model';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { GetMutableObject } from '@ft/lib/core-lib';
import { FT_StandardComponent } from '@ft/lib/standard-types-lib';
import { CloseIconButtonComponent, ProgressBarComponent } from '@furnas-technology/angular-library/components';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { OrganisationCardFields } from './organisation-card.formlyfields';

@Component({
	templateUrl: './organisation-card.component.html',
	styleUrls: ['./organisation-card.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: navigator.language ?? 'en-AU' }],
	imports: [
		CdkDrag,
		CloseIconButtonComponent,
		ProgressBarComponent,
		FontAwesomeModule,
		FormlyMatDatepickerModule,
		FormlyMaterialModule,
		FormlyModule,
		FormlySelectModule,
		FormsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatButtonModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationCardComponent extends FT_StandardComponent<Organisation> implements OnInit, AfterViewInit {
	protected organisationService = inject(OrganisationService);

	rowsLoading = computed(() => this.organisationService.isLoading());

	faUser = faUser;

	public fields: FormlyFieldConfig[] = OrganisationCardFields;

	options: FormlyFormOptions = {};

	// @Optional() @Inject(FT_DIALOG_DATA) readonly dialogConfig?: FT_MatDialogConfig<Organisation> | null
	constructor() {
		super();

		this.recordName.set('Organisation');

		/**
		 * load data on input changes
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - EFFECT - configId=${this.configId()}, modeType=${this.modeType()}`);
			// get organisation
			const { obj: mutableObject, reference } = GetMutableObject(
				this.modeType(),
				this.configId(),
				Organisation,
				this.organisationService.store.selectDocumentById,
			);
			this.loadModel(mutableObject, reference);
		});
	}

	loadModel(organisation: Organisation, reference: string): void {
		this.reference = reference;
		this.model.set(organisation);
	} // end loadModel

	onChangedOrganisation(organisationId: unknown) {}

	onModelChange(event: unknown): void {}
} // end class
